@import "bourbon";
@import "neat";
@import "libs/reset";
@import "libs/ie";

/*

font-face("source-sans-pro", "/font/source-sans-pro/source-sans-pro-regular");

*/



$max-width:1600px;

$desktop: new-breakpoint(min-width 769px 12);
$large: new-breakpoint(max-width 1024px 12);
$tablet: new-breakpoint(max-width 768px 6);
$tabletBIG: new-breakpoint(max-width 900px 6);
$mobile: new-breakpoint(max-width 480px 4);


/*
=================================================

									MIXINS

=================================================
*/

$pad : 60px;

@mixin jtContainer
{
	@include outer-container;
	width:calc(100% - #{$pad});
	max-width:$max-width;
	position:relative;
}


/*
=================================================

									TYPOGRAPHY

=================================================
*/

	@include font-face("socicon", "font/socicon");
	@include font-face("TradeGothicLTPro-Light", "font/2F4B91_0_0");


	$headlineSize : 36px;
		$headlineSizeLH : 40px;

	$subheadlineSize : 32px;
		$subheadlineSizeLH : 36px;

	$midSize : 24px;
		$midSizeLH : 26px;

	$bodySize : 16px;
		$bodySizeLH : 22px;

	$largeBodySize : 20px;
		$largeBodySizeLH : 26px;

	$standfirstSize : 27px;
		$standfirstSizeLH : 32px;

	$metaSize : 13px;
		$metaSizeLH : 18px;

	$mobileLargeSize : 24px;
		$mobileLargeSizeLH : 28px;


	@mixin headlineFont()
	{
		font-family:'TradeGothicLTPro-Light',sans-serif;
		font-weight:normal;
		font-style:normal;
		-webkit-font-smoothing:antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	@mixin bodyFont()
	{
		font-family:'TradeGothicLTPro-Light',sans-serif;
		font-weight:normal;
		font-style:normal;
		-webkit-font-smoothing:antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	@mixin socialFont()
	{
		font-family:'socicon';
		font-weight:normal;
		font-style:normal;
	}

	.social-icon {
		@include socialFont();
	}



/*
=================================================

									COLOURS

=================================================
*/

	$black : #000000;
	$white : #FFFFFF;
	$grey  : darken(#A4A09A,15);

	.black {
		color:$black;
	}
		.black-bg {
			background-color:$black;
		}

	.white {
		color:$white;
	}
		.white-bg {
			background-color:$white;
		}

	.grey {
		color:$grey;
	}
		.grey-bg {
			background-color:$grey;
		}

	$transSpeed : .5s;



/*
=================================================

									GENERAL STYLES

=================================================
*/

	html, body {
		@include bodyFont();
		font-size:$bodySize;
		line-height:$bodySizeLH;
		height:100%;

		&.nav-open,&.filter-open {
			overflow:hidden;

			@include media($mobile) {
				/*
				position:fixed;
				height:100%;
				width:100%;
				*/
			}
		}
	}

	* {
		outline:none;
	}

	html {
		&.mobile-open {

			@include media($tablet) {
				overflow:hidden;
			}
		}
	}

	.overflow {
		overflow:hidden;
	}
	html.overflow body {
		overflow:hidden;
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight:normal;
	}
	h1 {
		@include headlineFont();
		font-size:$subheadlineSize;
		line-height:$subheadlineSizeLH;

		@include media($mobile) {
			font-size:$mobileLargeSize;
			line-height:$mobileLargeSizeLH;
		}
	}
	h2 {
		@include headlineFont();
		font-size:$subheadlineSize;
		line-height:$subheadlineSizeLH;

		@include media($mobile) {
			font-size:$mobileLargeSize;
			line-height:$mobileLargeSizeLH;
		}
	}
	h3 {
		@include headlineFont();
		font-size:$standfirstSize;
		line-height:$standfirstSizeLH;

		@include media($mobile) {
			font-size:$mobileLargeSize;
			line-height:$mobileLargeSizeLH;
		}
	}

	p {
		strong {
			font-weight:normal;
			color:$black;
		}
	}

	.left-align {
		text-align:left;
	}
	.centre-align {
		text-align:center;
	}
	.right-align {
		text-align:right;
	}

	a {
		text-decoration:none;
		opacity:1;
		@include transition(opacity .2s);

		&:hover {
			opacity:0.6;
		}
	}

	img {
		max-width:100%;
		height:auto;
	}

	.clearfix {
		@include clearfix;
	}
	.container {
		@include jtContainer;

		@include media($mobile) {
			width:calc(100% - #{$pad / 2});
		}

		.left {
			@include span-columns(6);
			min-height:1px;

			@include media($tablet) {
				@include span-columns(6);
			}
		}
		.right {
			@include span-columns(6);
			min-height:1px;

			p, ul li, ol li {
				margin-bottom:$bodySizeLH;
				font-size:$largeBodySize;
				line-height:$largeBodySizeLH;
				color:$grey;

				&.standfirst {
					margin-bottom:$bodySizeLH;
					color:$black;
				}
				.wp-caption {
					max-width:100%;
					height:auto;

					img {
						height:auto;
					}
				}
			}

			ol {
			    margin-left: 26px;
			}

			&:nth-child(2),&.content-img-wrap {
				text-indent:0px;
			}

			@include media($tablet) {
				@include span-columns(6);
			}
		}
		&.standfirst {
			padding-top:100px;
			margin-bottom:20px;

			p {
				@include headlineFont();
				font-size:$standfirstSize;
				line-height:$standfirstSizeLH;

				@include media($mobile) {
					font-size:$mobileLargeSize;
					line-height:$mobileLargeSizeLH;
				}
			}

			@include media($mobile) {
				padding-top:0px;
			}
		}

		p a, p li a {
			color:$grey;
			@include transition(color .3s);
			text-decoration:underline;

			&:hover {
				opacity:1;
				color:$black;
			}
		}
	}



/*
======================================

	EXTEND STYLES

======================================
*/

	.vertical-align-middle {
		position:absolute;
		top:50%;
	}

	#ajax_pool {
		html.home-page & {
			height:100%;
		}
	}



/*
======================================

	PARTIALS

======================================
*/

	@import "partials/slider";
	@import "partials/nav";
	@import "partials/home";
	@import "partials/work";
	@import "partials/news";
	@import "partials/singleproject";
	@import "partials/single";
	@import "partials/practice";
	@import "partials/contact";
	@import "partials/footer";


	.holding {
		position:absolute;
		display:table;
		width:100%;
		height:100%;
		background-color:$black;
		color:$white;

		.holding-centre {
			display:table-cell;
			vertical-align:middle;

			h1, h3, ul, .holding-logo-wrap {
				max-width:640px;
				width:100%;
				margin:0 auto;
			}

			h3 {
				font-size:$bodySize;
				line-height:$bodySizeLH;
				margin-top:$bodySizeLH;
			}

			.holding-logo {
				width:200px;
				height:63px;
				background-image:url(http://dev.shewasonly.co.uk/electrolight/wp-content/themes/swotheme/assets/images/white_logo.png);
				background-repeat:no-repeat;
				display:inline-block;
				text-indent:-9999px;
				margin-bottom:24px;
			}

			ul {
				margin-top:50px;

				li {
					display:inline-block;
					margin-right:10px;

					a {
						color:$white;
					}
				}
			}
		}
	}







